<template>
  <div id="product-usage-description-wrapper">
    <div class="usage-description">
      <h2>Using {{ productDescription.title }}</h2>
      <p>{{ productDescription.usageDescription }}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: ["productDescription"],
};
</script>
<style lang="scss" scoped>
#product-usage-description-wrapper {
  background-color: #aec99c;
  width: 100%;
  display: flex;
  justify-content: center;
  .usage-description {
    width: 80vw;
    max-width: 1000px;
    padding: 10px 0 30px 0;
    font-style: normal;
    @media only screen and (min-width: 1023px) {
      padding: 30px 0 80px 0;
    }
  }
}
</style>
