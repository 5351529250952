<template>
  <div id="product-detail-hero-content">
    <div id="product-detail-hero-content-container">
      <div id="product-detail-hero-carousel-container">
        <embla-carousel carouselName="product-hero-carousel">
          <div
            class="embla-slide"
            v-for="(images, id) in productItem.imgNameList"
            :key="id"
          >
            <div class="inner">
              <picture>
                <source
                  :srcset="require(`@/assets/images/products/${images}.webp`)"
                  type="image/webp"
                />
                <source
                  :srcset="require(`@/assets/images/products/${images}.jpg`)"
                  type="image/jpeg"
                />
                <img
                  class="product-detail-hero-product-pic img"
                  :src="require(`@/assets/images/products/${images}.jpg`)"
                  alt="product-pic"
                />
              </picture>
            </div>
          </div>
        </embla-carousel>
      </div>

      <div class="product-detail-hero-product-content">
        <h1 v-if="productItem.promotion" class="product-detail-hero-promotion">
          {{ productItem.promotionDescription }}
        </h1>
        <h1>{{ productItem.title }}</h1>
        <div class="product-detail-hero-product-content-description">
          <span class="white-space">
            {{ productItem.description }}
          </span>
          <div class="product-detail-hero-price">
            <h2>
              {{ productItem.price }}
            </h2>
            <div class="product-detail-hero-noFontWeight-order-enquiry">
              <h2>
                <i>Product enquiries</i>
              </h2>
              <button
                class="button contact-us-button"
                @click="$emit('open-modal')"
              >
                Contact Us
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EmblaCarousel from "@/components/EmblaCarousel";
export default {
  name: "product-detail-hero",
  components: {
    EmblaCarousel,
  },
  props: ["productItem"],
};
</script>
<style lang="scss" scoped>
#product-detail-hero-content {
  background-color: #aec99c;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
#product-detail-hero-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 100px 0 30px 0;
}
#product-detail-hero-carousel-container {
  width: 80vw;
  height: 80vw;
  max-width: 500px;
  max-height: 500px;
  border: 3px solid white;
}
.product-detail-hero-product-content {
  padding: 40px 0 0 100px;
  text-align: left;
  position: relative;
  width: 550px;
  h1 {
    font-size: 2.5rem;
  }
}
.product-detail-hero-noFontWeight-order-enquiry {
  display: flex;
  flex-direction: row;
  align-items: center;
  h2 {
    min-width: 220px;
  }
  .contact-us-button {
    padding: 20px 20px;
    margin: 20px;
    display: block;
    font-size: 1rem;
    min-width: 130px;
  }
}
.white-space {
  white-space: pre-line;
}
.product-detail-hero-promotion {
  color: rgb(192, 15, 15);
}

@media only screen and (max-width: 1208px) {
  .product-detail-hero-product-content {
    padding: 40px;
    width: 80vw;
    text-align: center;
    padding-top: 60px;
    .product-detail-hero-product-content-description {
      width: 80vw;
    }
  }
  .product-detail-hero-price {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .product-detail-hero-noFontWeight-order-enquiry {
    flex-direction: column;
  }
}
</style>
