<template>
  <div id="nu-features">
    <h2>Features of NU Hydrogen Water</h2>
    <div id="nu-animation-container">
      <div class="small-box-description-wrapper left">
        <div
          class="small-box-decription"
          @click="boxClicked('mineralisation-box')"
          id="mineralisation-box"
        >
          <h2>Mineralisation</h2>
        </div>
        <div
          class="small-box-decription"
          @click="boxClicked('hydro-water-box')"
          id="hydro-water-box"
        >
          <h2>Hydrogenated Water</h2>
        </div>
        <div
          class="small-box-decription"
          @click="boxClicked('oxi-reduct-box')"
          id="oxi-reduct-box"
        >
          <h2>Oxidation Reduction Potential</h2>
        </div>
        <div
          class="small-box-decription"
          @click="boxClicked('mild-alkaline-box')"
          id="mild-alkaline-box"
        >
          <h2>Mild Alkaline</h2>
        </div>
      </div>
      <div id="nu-animation-main-display">
        <picture>
          <source
            :srcset="
              require(`@/assets/images/products/NHWB/features-images/${this.nuChoosenImgPath}.webp`)
            "
            type="image/webp"
          />
          <source
            :srcset="
              require(`@/assets/images/products/NHWB/features-images/${this.nuChoosenImgPath}.jpg`)
            "
            type="image/jpeg"
          />
          <img
            class="nu-animation-main-display-img"
            :src="
              require(`@/assets/images/products/NHWB/features-images/${this.nuChoosenImgPath}.jpg`)
            "
            alt="product-pic"
            width="100%"
          />
        </picture>
        <div class="nu-animation-main-display-description">
          <h1>{{ nuChoosenTitle }}</h1>
          <span>{{ nuChoosenDescription }}</span>
        </div>
      </div>
      <div class="small-box-description-wrapper right">
        <div
          class="small-box-decription"
          @click="boxClicked('micro-cluster-box')"
          id="micro-cluster-box"
        >
          <h2>Micro Cluster</h2>
        </div>
        <div
          class="small-box-decription"
          @click="boxClicked('structured-water-box')"
          id="structured-water-box"
        >
          <h2>Structured Water</h2>
        </div>
        <div
          class="small-box-decription"
          @click="boxClicked('anti-oxidant-box')"
          id="anti-oxidant-box"
        >
          <h2>Antioxidant</h2>
        </div>
      </div>
    </div>
    <div
      v-for="features in NUDescriptionList"
      :key="features.id"
      id="nu-mobile-features"
    >
      <h2>{{ features.title }}</h2>
      <p>{{ features.description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nuChoosenTitle: "Mineralisation",
      nuChoosenDescription:
        "Quality water containing essential minerals for overall healthy function of the body system.",
      nuChoosenImgPath: "mineralisation",
      prevChoosenId: "mineralisation-box",
      NUDescriptionList: [
        {
          id: "mineralisation-box",
          title: "Mineralisation",
          imgPath: "mineralisation",
          description:
            "Quality water containing essential minerals for overall healthy function of the body system.",
        },
        {
          id: "hydro-water-box",
          title: "Hydrogenated Water",
          imgPath: "hydrogenated-water",
          description:
            "Higher dose of hydrogen molecules which act as an anti-inflammatory agent.",
        },
        {
          id: "oxi-reduct-box",
          title: "Oxidation Reduction Potential",
          imgPath: "oxidation-reduction-potential",
          description:
            "A negative ORP value in the water indicates higher antioxidant level which helps to protect cells from free radical damages and ageing process.",
        },
        {
          id: "mild-alkaline-box",
          title: "Mild Alkaline",
          imgPath: "mild-alkaline",
          description:
            "Contains optimum pH value of 8 to 8.5 to balance your body system for a moderate akalinity which helps to reduce inflammation and acid waste.",
        },
        {
          id: "micro-cluster-box",
          title: "Micro Cluster",
          imgPath: "micro-cluster",
          description:
            "Smaller cluster size of water molecules enhances hydration through effective cell penetration, allowing your body to better absorb the essential nutrients.",
        },
        {
          id: "structured-water-box",
          title: "Structured Water",
          imgPath: "structured-water",
          description:
            "When water molecules are formed in a perfect hexagon geometric formation, maintained at its natural vibrational frequency and free from man-made contamination, this is water meant to be enjoyed as nature intended.",
        },
        {
          id: "anti-oxidant-box",
          title: "Antioxidant",
          imgPath: "anti-oxidant",
          description:
            "Antioxidants are key in preventing the growth of free radicals in the body, which causes cellular damage that speeds up the ageing process.",
        },
      ],
    };
  },
  methods: {
    boxClicked(boxId) {
      var choosenFeature = this.NUDescriptionList.find((x) => x.id === boxId);
      this.nuChoosenTitle = choosenFeature.title;
      this.nuChoosenDescription = choosenFeature.description;
      this.nuChoosenImgPath = choosenFeature.imgPath;
      document.getElementById(this.prevChoosenId).style.cssText =
        "background-color: white; color:black";
      document.getElementById(boxId).style.cssText =
        "background-color: #107e79; color:white";
      this.prevChoosenId = boxId;
    },
  },
};
</script>
<style lang="scss" scoped>
#mineralisation-box {
  background-color: #107e79;
  color: #f4f4f0;
}
#nu-features {
  width: 100%;
  background-color: #aec99c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
}
#nu-animation-container {
  height: 600px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #aec99c;
}
#nu-animation-main-display {
  border-radius: 25px 25px 0 0;
  border-bottom: 0px;
  background-color: #e4d8cf;
  width: 400px;
  height: 550px;
  border-radius: 25px 25px 25px 25px;
  -moz-box-shadow: 0 5px 5px #8f8f8f;
  -webkit-box-shadow: 0 5px 5px #8f8f8f;
  box-shadow: 0 5px 5px #8f8f8f;
  .nu-animation-main-display-img {
    max-width: 100%;
    max-height: 80%;
    display: block;
    margin: auto;
    border-radius: 25px 25px 0 0;
  }
  .nu-animation-main-display-description {
    text-align: left;
    padding: 1.8em;
    padding-top: 0 !important;
    padding-bottom: 0.7em;
    border-top: 0px;
    border-radius: 0 0 25px 25px;
    text-decoration: none !important;
    text-align: center;
  }
}
.small-box-description-wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.small-box-decription {
  height: 100px;
  width: 300px;
  border-radius: 25px 25px 25px 25px;
  background-color: white;
  margin: 12px;
  -moz-box-shadow: 0 5px 5px #8f8f8f;
  -webkit-box-shadow: 0 5px 5px #8f8f8f;
  box-shadow: 0 5px 5px #8f8f8f;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #107e79;
    color: #f4f4f0;
  }
}
#nu-mobile-features {
  padding: 20px 30px;
  display: none;
  max-width: 400px;
  p {
    color: #313131;
  }
}

@media only screen and (max-width: 1023px) {
  #nu-mobile-features {
    display: block;
  }
  #nu-animation-container {
    display: none;
  }
}
</style>
