<template>
  <div>
    <!-- <hero /> -->
    <product-detail-section
      :productId="getProductId"
      id="product-detail-section-id"
    />
  </div>
</template>
<script>
import ProductDetailSection from "@/components/ProductDetailPage/ProductDetailSection.vue";

export default {
  name: "ProductDetail",
  components: {
    ProductDetailSection,
  },
  metaInfo: {
    title: "Product Detail",
  },
  computed: {
    getProductId() {
      return this.$route.params.productId;
    },
  },
};
</script>
