<template>
  <div class="product-detail-section-video-content">
    <iframe
      class="product-detail-section-video-content-iframe"
      id="iframe-video"
      name="product-video"
      referrerpolicy="no-referrer"
      sandbox="allow-same-origin allow-scripts allow-presentation"
      src=""
      :data-src="videoLink"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</template>
<script>
export default {
  props: ["videoLink"],
  mounted() {
    const iframeEle = document.getElementById("iframe-video");
    iframeEle.setAttribute("src", iframeEle.getAttribute("data-src"));
  },
};
</script>
<style lang="scss" scoped>
.product-detail-section-video-content {
  background-color: #aec99c;
  height: 100%;
  width: 100%;
  display: inline-block;
  padding: 100px 0 50px 0;
  @media only screen and (max-width: 1208px) {
    padding-top: 50px;
  }
}
.product-detail-section-video-content-iframe {
  width: 1200px;
  height: 675px;
  @media only screen and (max-width: 1330px) {
    width: 800px;
    height: 350px;
  }
  @media only screen and (max-width: 900px) {
    width: 70vw;
  }
}
</style>
