<template>
  <div id="product-detail-section-content">
    <product-detail-hero
      :productItem="getProductItem"
      @open-modal="onModalChange"
    />
    <product-detail-description :productDescription="getProductItem" />
    <m-w-e-t-circle-animation v-if="getProductItem.id === '3'" />
    <n-u-box-animation v-if="getProductItem.id === '2'" />

    <product-detail-video-section
      v-if="checkVideoLink"
      :videoLink="getVideoLink"
    />

    <!-- <product-detail-price-section
      v-if="checkProductPrice"
      :productPriceList="getProductPriceList"
    /> -->
    <div id="product-detail-section-order-enquiries-wrapper">
      <div class="product-detail-section-order-enquiries">
        <h2>
          <i>Product enquiries</i>
        </h2>
        <button class="button contact-us-button" @click="onModalChange">
          Contact Us
        </button>
      </div>
      <a
        v-if="getProductItem.id === '3'"
        class="product-detail-section-order-enquiries-brochure-download"
        href="/ALPHA MWET Brochure.pdf"
        download
      >
        <i>Download Brochure</i>
      </a>
    </div>
    <div>
      <modal-email :active.sync="showModal" @close="onModalChange" />
    </div>
  </div>
</template>
<script>
import ProductDetailHero from "./ProductDetailHero";
import ProductDetailVideoSection from "./ProductDetailVideoSection";
import ModalEmail from "@/components/ModalEmail.vue";
import MWETCircleAnimation from "./MWETCircleAnimation.vue";
import NUBoxAnimation from "./NUBoxAnimation.vue";
import ProductDetailDescription from "./ProductDetailDescription.vue";
export default {
  name: "product-detail-section",
  components: {
    ProductDetailHero,
    ProductDetailVideoSection,
    ModalEmail,
    MWETCircleAnimation,
    NUBoxAnimation,
    ProductDetailDescription,
  },
  props: ["productId"],
  data() {
    return {
      meta: {
        title: undefined,
      },
      showModal: false,
      products: [
        {
          id: "1",
          title: "Holographic Harmoniser",
          descriptionTitle: "Harmonise Your Life",
          description:
            "Designed to protect you from the side-effects of Electromagnetic Waves(EMW), the Holographic Harmoniser was developed with an advanced 'Wave Modulation Technology' to regulate and transform harmful erratic waves into harmonious waves. This helps to reduce the risk of radiation and imbalances caused by the prolonged use of cellphone, laptop and other electronic devices. \n\n The Holographic Harmoniser is the first in Singapore to be tested & certified by TUV PSB Singapore, for radiation safety.",
          imgName: "HolographicTransparent",
          price: "SGD 38 (Promotion Price)",
          promotion: false,
          imgNameList: [
            "HH/carousel/HH1",
            "HH/carousel/HH2",
            "HH/carousel/HH3",
            "HH/carousel/HHCarousel_1",
            "HH/carousel/HHCarousel_2",
            "HH/carousel/HHCarousel_3",
            "HH/carousel/HHCarousel_router",
          ],
          priceOption: false,
          videoOption: true,
          videoLink: "https://www.youtube.com/embed/oNaPQEEt7Z8",
          usageDescription:
            "The Holographic Harmoniser can be applied on cell phones, computers, tablets, laptops, TV and wifi routers. The best position to activate the Holographic Harmoniser is to paste it at the center of your device or near the power output of the device.  Embedded with Wave Modulation Technology (WMT), the Holographic Harmoniser regulates the electromagnetic waves, act as a harmonising field to our brainwaves and bioenergy, protects and enhances our mental and physical wellbeing. ",
        },
        {
          id: "2",
          title: "NU Hydrogen Water Bottle",
          descriptionTitle: "Hydration redefined",
          description:
            "Hydrogen-Rich Water is different from regular water. It is infused with molecular hydrogen rich in antioxidant capable of fighting against inflammatory conditions, free radicals and oxidative stress in human cells.",
          imgName: "NUWaterTransparent",
          price: "SGD 138",
          promotion: true,
          promotionDescription: "Buy 1 Get 1 Free!",
          imgNameList: [
            "NHWB/carousel/NHWB1",
            "NHWB/carousel/NHWB2",
            "NHWB/carousel/NHWB3",
            "NHWB/carousel/NHWBCarousel_park",
            "NHWB/carousel/NHWBCarousel_parkDrinking",
            "NHWB/carousel/NHWBCarousel_work",
          ],
          priceOption: false,
          videoOption: false,
          usageDescription:
            "Water is essential for the kidney and other organs to function properly.  Around 60% of the body is made up of water and our blood is 90% water.  Being well hydrated improves digestion, blood circulation, brain and heart functions, prevents kidney damage and premature wrinkling.  Hydrate yourself frequently with hydrogen water helps to reduce inflammation, fight against free radicals and oxidative stress, boost skin repair and strengthens your immune system.",
        },
        {
          id: "3",
          title: "Alpha MWET Device",
          descriptionTitle: "Restore Harmony Within Chaos",
          description:
            "An unique 6-Therapy Energy Waves to activate and stimulate the body cells, bioenergy(Qi) and blood circulation. This unique 6 therapy-energy waves enhance the physical functions of our internal organs and immune system, energise the Qi flow and helps improve our overall health and wellness.",
          imgName: "AlphaMachineGraphicTransparent",
          price: "",
          imgNameList: [
            "AMWET/carousel/AMWET1",
            "AMWET/carousel/AMWET2",
            "AMWET/carousel/AMWET3",
            "AMWET/carousel/AMWETCarousel_bottomThigh",
            "AMWET/carousel/AMWETCarousel_calf",
            "AMWET/carousel/AMWETCarousel_feet",
            "AMWET/carousel/AMWETCarousel_hand",
            "AMWET/carousel/AMWETCarousel_lowerBack",
            "AMWET/carousel/AMWETCarousel_sitOnFloor",
            "AMWET/carousel/AMWETCarousel_stomach",
            "AMWET/carousel/AMWETCarousel_upperBack",
          ],
          videoOption: true,
          videoLink: "https://youtube.com/embed/VcLRXkuyTjw",
          priceOption: true,
          priceList: [
            {
              headingTitle: "A",
              title: "Lease for 1-Month",
              description:
                "The best way to experience the healing effects of Alpha MWET is to do it everyday! Alpha MWET device can be leased for a month at S$850, refundable deposit applies.",
            },
            {
              headingTitle: "B",
              description:
                "Alpha MWET device can be purchased at S$8,800 and use anytime at home or in the office to benefit everyone in the family or to recharge yourself while at work!",
            },
          ],
          usageDescription:
            "As a preventive health and wellness device, Alpha MWET can be placed and used on different parts of your body for an overall comprehensive therapy or for a specific targeted therapy.  To enjoy the therapeutic effects of Alpha MWET, it is best to experience it daily for at least an hour a day while at home or at work.  Consistent and regular use of Alpha MWET helps to strengthen energy (qi) flow, boost immune system, protects from free radical damages, promotes vitality and anti-ageing.",
        },
      ],
    };
  },
  mounted() {
    if (this.productId === "1") {
      this.meta.title = "Holographic Harmoniser";
    } else if (this.productId === "2") {
      this.meta.title = "NU Hydrogen Water Bottle";
    } else if (this.productId === "3") {
      this.meta.title = "Alpha MWET Device";
    }
  },
  metaInfo() {
    return {
      title: this.meta.title,
    };
  },
  computed: {
    getProductItem() {
      return this.products.find((x) => x.id === this.productId);
    },
    checkProductPrice() {
      return this.products.find((x) => x.id === this.productId).priceOption;
    },
    getProductPriceList() {
      return this.products.find((x) => x.id === this.productId).priceList;
    },
    checkVideoLink() {
      return this.products.find((x) => x.id === this.productId).videoOption;
    },
    getVideoLink() {
      return this.products.find((x) => x.id === this.productId).videoLink;
    },
  },
  methods: {
    onModalChange() {
      this.showModal = !this.showModal;
      document.body.classList.toggle("modal-open");
      if (this.showModal) {
        document.getElementById("email-modal").focus();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#product-detail-section-order-enquiries-wrapper {
  background-color: #aec99c;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px 0px 50px 0px;
  h2 {
    margin: 0;
    padding: 20px;
  }
}
.product-detail-section-order-enquiries {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.contact-us-button {
  margin: 20px;
  padding: 20px 20px;
  display: block;
  font-size: 1rem;
}
.product-detail-section-order-enquiries-brochure-download {
  color: grey;
  font-size: 2rem;
  padding: 20px;
}
</style>
