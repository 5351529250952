<template>
  <div id="hero-description-content">
    <h2>The Multi-Wave Energy Therapy comprises of:</h2>
    <div ref="boxWrapper" id="box-description-wrapper">
      <div class="box-description first">
        <h2 class="description-title">Thermobustion Therapy</h2>
        <p>Heat treatment to the acupoints to enhance organ functions</p>
      </div>
      <div class="box-description second">
        <h2 class="description-title">Negative Potential Therapy</h2>
        <p>
          Strengthens energy(Qi) flow and blood circulation, boost cells
          regeneration
        </p>
      </div>
      <div class="box-description third">
        <h2 class="description-title">Pulse Electro Magnetic Therapy</h2>
        <p>
          Low frequency electromagnetic waves to enhance circulation and clear
          meridian blockages
        </p>
      </div>
      <div class="box-description fourth">
        <h2 class="description-title">Far-Infrared Therapy</h2>
        <p>Promotes internal warming to strengthen the immune system</p>
      </div>
      <div class="box-description fifth">
        <h2 class="description-title">Negative Ion Therapy</h2>
        <p>
          Protects the body from free radical damages and promotes anti-ageing
        </p>
      </div>
      <div class="box-description sixth">
        <h2 class="description-title">Biofield Regulation Therapy</h2>
        <p>
          Improves body's biofield coherence to stabilise overall body functions
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "mwet-circle-animation",
  data() {
    return {
      compObserver: null,
    };
  },
  mounted() {
    const options = {
      root: null,
      rootMargin: "140px 0px 0px 0px",
      threshold: [0, 0.25, 0.5, 0.75, 1],
    };
    const observer = new IntersectionObserver((entries) => {
      for (const entry of entries) {
        if (entry.intersectionRatio > 0.2) {
          entry.target.classList.toggle("active");
          this.compObserver.unobserve(entry.target);
        }
      }
    }, options);
    observer.observe(this.$refs.boxWrapper);
    this.compObserver = observer;
  },
  destroyed() {
    if (this.compObserver) {
      this.compObserver.disconnect();
    }
  },
};
</script>
<style lang="scss" scoped>
#hero-description-content {
  background-color: #aec99c;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#box-description-wrapper {
  display: flex;
  justify-content: center;
  height: 750px;
  width: 1300px;
  position: relative;

  @media only screen and (min-width: 1023px) {
    opacity: 0;
    &.active {
      -webkit-transition: opacity 1s ease-in-out, transform 1s;
      -moz-transition: opacity 1s ease-in-out, transform 1s;
      -ms-transition: opacity 1s ease-in-out, transform 1s;
      -o-transition: opacity 1s ease-in-out, transform 1s;
      transition: opacity 1s ease-in-out, transform 1s;
      opacity: 1;
      -webkit-transform: translateY(-100px);
      -moz-transform: translateY(-100px);
      -ms-transform: translateY(-100px);
      -o-transform: translateY(-100px);
      transform: translateY(-100px);
    }
    margin-top: 140px;
  }
}

.box-description {
  width: 210px;
  height: 210px;
  font-size: 1rem;
  -moz-box-shadow: 0 5px 5px #8f8f8f;
  -webkit-box-shadow: 0 5px 5px #8f8f8f;
  box-shadow: 0 5px 5px #8f8f8f;
  background-color: #107e79;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 50%;
  position: absolute;
  color: white;
}
.first {
  top: 0;
}
.second {
  top: 100px;
  right: 240px;
}
.third {
  bottom: 100px;
  right: 240px;
}
.fourth {
  bottom: 0;
}
.fifth {
  bottom: 100px;
  left: 240px;
}
.sixth {
  top: 100px;
  left: 240px;
}
@media only screen and (max-width: 1023px) {
  #box-description-wrapper {
    flex-direction: column;
    align-items: center;
    width: 80vw;
    height: 100%;
  }
  .box-description {
    position: initial;
    margin: 20px;
  }
}
</style>
